import React from "react";
import { Switch, Route } from "react-router-dom";
import Abstracts from "./components/Abstracts";
import Home from "./components/Home";
import TheaterSchedule from "./components/TheaterSchedule";
import HematologyMedicines from "./components/hematology/OurMedicines";
import HematologyEducationalResources from "./components/hematology/OurEducationalResources";
import PageNotFound from "./components/404";
import ComingSoon from "./components/comingSoon"
import { ModalProvider } from "./components/UI/ContactUsModal";

const App = () => {
  return (
    <div className="App">
      <ModalProvider>
        <Switch>
          <Route exact path="/" component={ ComingSoon } />
          <Route exact path="/hememalig21" component={ Home } />
          <Route exact path="/hememalig21/abstracts" component={ Abstracts } />
          <Route exact path="/hememalig21/product-theater-schedule" component={ TheaterSchedule } />
          <Route exact path="/hememalig21/hematology/our-medicines" component={ HematologyMedicines } />
          <Route exact path="/hememalig21/hematology/our-educational-resources" component={ HematologyEducationalResources } />
          <Route path="*" component={ PageNotFound } />
        </Switch>
      </ModalProvider>
    </div>
  );
};

export default App;
