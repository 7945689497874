import React, { Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { useModal } from "./UI/ContactUsModal";
import { useIsMobile } from "../utils/hooks";

const TheaterSchedule = () =>
{
  const { setIsContactDialogOpen } = useModal();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <Header />

      { !isMobile &&
        <section className="banner-theater-schedule-wrapper" style={{ backgroundImage: "url(/img/banners/banner-theater-schedule.png)" }}>
          <Container>
            <h1>ASH 2021 Product Theater Schedule</h1>
          </Container>
        </section>
      }
      {
        isMobile &&
        <section className="banner-theater-schedule-wrapper" style={{ backgroundImage: "url(/img/banners/banner-theater-schedule-mobile.png)" }}>
          <Container>
            <h1>ASH 2021 Product Theater Schedule</h1>
          </Container>
        </section>
      }

      <section className="product-theater-schedule-cards">
              <Container>
                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Farhad Ravandi, MD</p>
                    <p className="event-name">Continued Treatment in AML: {!isMobile && <br />} The Role of Onureg (azacitidine) tablets</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Wednesday, September 8, 2021<br />11:41am – 12:41pm CT</p>
                    <a href="https://zoom.us/j/99039947197" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">William Velasquez, MD</p>
                    <p className="event-name">Discover the Role of Two Immunotherapies in Myeloma Treatment</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Thursday, September 9, 2021<br />12:08pm – 1:08pm CT</p>
                    <a href="https://zoom.us/j/95713459444" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Thomas William LeBlanc, MD</p>
                    <p className="event-name">A Treatment Option for Patients with Myelodysplastic Syndromes</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Friday, September 10, 2021 / <br />12:08pm – 1:08pm CT</p>
                    <a href="https://zoom.us/j/91859255431" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

              </Container>
            </section>

      { !isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      { isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy-mobile.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      <Footer />
    </Fragment>
  );
}

export default React.memo(TheaterSchedule);
