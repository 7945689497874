import React, { Fragment, useEffect } from "react";
import {useMetaInfo} from "../utils/hooks";

const PageNotFound = () =>
{
  const { updateMetaTitle, updateMetaDescription } = useMetaInfo();

  useEffect(() =>
  {
    updateMetaTitle("BMS Virtual 2021 - Coming Soon");
    updateMetaDescription("BMS Virtual 2021 - Coming Soon");
  }, [ updateMetaTitle, updateMetaDescription ]);

  return (
    <Fragment>
      <div className="main" style={{backgroundImage: "url(/img/comingsoon/background.png)" }}>
        <div className="images">
            <img src="/img/comingsoon/loading-logo.png" alt="" className="logo" />
            <br/><br/><br/> <br/><br/><br/>
            <img src="/img/comingsoon/comingsoon.png" alt="" className="coming-soon" />
        </div>
      </div>

    </Fragment>
  );
};

export default React.memo(PageNotFound);
